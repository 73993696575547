import {Link, Stack, Typography, useTheme} from "@mui/material";
import FullPageLoader from "../components/FullPageLoader";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {captureException} from "@sentry/react";
import {useAuthContext} from "../contexts/auth";
import {Link as RouterLink} from "react-router-dom";

export default function Callback() {
	const navigate = useNavigate();
	const {user, refreshUser} = useAuthContext();
	const theme = useTheme();

	const [error, setError] = useState<string | null>(null);

	// Redirect the user if they are logged in already
	useEffect(() => {
		if (user) {
			navigate("/");
		}
	}, [user, navigate]);

	useEffect(() => {
		const code = new URLSearchParams(window.location.search).get("code");

		// If Google hasn't sent us the oauth code, send them back to google
		if (!code) {
			captureException("Code was not received from google.");
			console.error("Code was not received from google.");
			return setError("Code was not received from google.");
		}

		axios.post("/auth/login/google/callback", {
			code
		})
			.then(() => {
				refreshUser()
					.then(() => {
						// User will be redirected in the useEffect above
					})
					.catch(err => {
						console.error(err);
						captureException(err);
						navigate("/login");
					})
			})
			.catch(err => {
				if (err.response.data?.error === "ERR_UNAUTHORIZED") return setError("Please use your crossroads email ending in xrds.org");
				if (err.response.data?.error === "ERR_CODE_INVALID") return navigate("/login");

				console.error(err);
				captureException(err);
				setError("There was an unknown error. Please try again later.");
			});
	}, [navigate, refreshUser]);

	if (error) return (
		<Stack spacing={2}>
			<Typography variant="h5" sx={{color: theme.palette.error.main}} textAlign="center">{error}</Typography>
			<Link component={RouterLink} to="/login" variant="h5" textAlign="center">Click here to login again</Link>
		</Stack>
	);

	return <FullPageLoader/>;
}