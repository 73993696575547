/**
 * Get the graduation year from a crossroads email formatted in the tucker.r23@ format
 * @param email Crossroads email
 * @returns Graduation year, or null if unavailable
 */
export default function gradYearFromEmail(email: string): string | null {
	if (!email) return null;

	const emailSplit = email.split("@");

	if (emailSplit.length === 0) return null;

	const user = emailSplit[0];
	const userSplit = user.split(".");

	if (userSplit.length !== 2) return null;
	if (userSplit[1].length !== 3) return null;

	return "'" + userSplit[1].substring(1);
}