import axios from "axios";

export interface IUser {
	id: string;
	googleId: string;
	admin: boolean;
	name: string;
	email: string;
}

/**
 * Get all users and admins from the database
 */
export async function getAllUsers(): Promise<{users: IUser[], admins: IUser[]}> {
	const response = await axios.get("/admin/user/list");

	let users: IUser[] = [];
	let admins: IUser[] = [];

	response.data.users.forEach((user: IUser) => {
		if (user.admin) admins.push(user);

		users.push(user);
	})

	return {users, admins};
}

export interface IRequest {
	id: string;
	userId: string;
	timestamp: Date;
	note: string;
	completed: boolean;
}

/**
 * Get all pending requests
 */
export async function getAllRequests(): Promise<IRequest[]> {
	const response = await axios.get("/admin/user/list_requests");

	return response.data.requests;
}

export interface ISignOut {
	id: string;
	userId: string;
	adminId: string;
	timestampOut: Date;
	timestampIn: Date;
	note: string;
}

/**
 * Get all signouts on a day, both currently signed out students and signed back in students
 */
export async function getSignouts(date?: Date): Promise<{history: ISignOut[], signouts: ISignOut[]}> {
	const day = date || new Date();

	const response = await axios.get(`/admin/user/list_signouts?day=${day.toUTCString()}`);
	const {history, signouts} = response.data;

	return {history, signouts};
}

/**
 * Sign out a user
 *
 * @param userId ID of user to sign out
 * @param note Note to attach to sign out
 */
export async function signOutUser(userId: string, note: string): Promise<void> {
	await axios.post("/admin/user/signout", {
		user: userId,
		note: note
	});
}

/**
 * Complete a sign out request
 *
 * @param requestId Request ID
 */
export async function completeRequest(requestId: string): Promise<void> {
	await axios.post("/admin/user/request/complete", {
		id: requestId
	});
}

/**
 * Sign back in a user. The user must have been signed out already.
 *
 * @param userId ID of user to sign in
 */
export async function signInUser(userId: string): Promise<void> {
	await axios.post("/admin/user/signin", {
		user: userId,
		timestampIn: new Date()
	})
}