import axios from "axios";

/**
 * Get the Google oAUTH redirect url
 */
export async function getRedirectURL(): Promise<string> {
	const response = await axios.get("/auth/login/google");

	return response.data.redirect_url;
}

export async function logout(): Promise<void> {
	await axios.post("/auth/logout");
}