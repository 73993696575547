import {Link as RouterLink} from "react-router-dom";
import {useEffect, useState} from "react";
import {Box, Button, Stack, TextField, Typography, useTheme} from "@mui/material";
import dayjs, {Dayjs} from "dayjs";
import {captureException} from "@sentry/react";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import SignOutHistory from "../../components/admin/SignOutHistory";
import FullPageLoader from "../../components/FullPageLoader";
import SignedOutStudents from "../../components/admin/SignedOutStudents";
import {ChevronLeft, Print} from "@mui/icons-material";
import {getAllUsers, getSignouts, ISignOut, IUser} from "../../api/admin";

export default function AdminHistory() {
	const theme = useTheme();

	const [loading, setLoading] = useState<boolean>(true);
	const [globalError, setGlobalError] = useState<string | null>(null);

	const [users, setUsers] = useState<IUser[]>([]);
	const [admins, setAdmins] = useState<IUser[]>([]);
	const [historyDate, setHistoryDate] = useState<Dayjs | null>(dayjs());
	const [signOutHistory, setSignOutHistory] = useState<ISignOut[]>([]);
	const [signOuts, setSignOuts] = useState<ISignOut[]>([]);

	// On initial load, get all users
	useEffect(() => {
		getAllUsers()
			.then(response => {
				setUsers(response.users);
				setAdmins(response.admins);
			})
			.catch(err => {
				console.error(err);
				captureException(err);
				setGlobalError("There was an unknown error. Please try again later.");
			})
	}, []);

	// Whenever the date changes, update the sign-outs on that day
	useEffect(() => {
		if (!historyDate) return;

		getSignouts(historyDate.toDate())
			.then(response => {
				setSignOuts(response.signouts);
				setSignOutHistory(response.history);
			})
			.catch(err => {
				console.error(err);
				captureException(err);
				setGlobalError("There was an unknown error. Please try again later.");
			})
			.finally(() => {
				setLoading(false);
			})
	}, [historyDate]);

	if (loading) return <FullPageLoader/>;
	if (globalError) return <Typography variant="h4" sx={{color: theme.palette.error.main}}>{globalError}</Typography>;

	return (
		<Stack spacing={2} pl={5} pr={5} pt={4}>
			<Box>
				<Typography variant="h4">XRDS Sign Out</Typography>
				<Typography variant="h4">
					Sign Out History {historyDate && " - " + historyDate.format("M/D/YYYY")}
				</Typography>
				<Stack direction="row" spacing={1}>
					<Button variant="contained" size="medium" component={RouterLink} to="/admin">
						<ChevronLeft fontSize="medium"/> Return to dashboard
					</Button>
					<Button variant="contained" size="medium" onClick={() => window.print()}>
						Print <Print fontSize="medium"/>
					</Button>
				</Stack>
			</Box>
			<hr/>
			<Box>
				<Typography variant="subtitle2">Date to get history from</Typography>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						value={historyDate}
						disableFuture={true}
						onChange={(value) => {
							setHistoryDate(value);
						}}
						renderInput={(params) => <TextField {...params} size="medium"/>}
					/>
				</LocalizationProvider>
			</Box>

			<Box>
				<Typography variant="body1" fontWeight="bold">Signed out students history:</Typography>
				<SignedOutStudents
					signouts={signOuts}
					users={users}
					admins={admins}
				/>
			</Box>

			<Box>
				<Typography variant="body1" fontWeight="bold">Signed back in history:</Typography>
				<SignOutHistory
					history={signOutHistory}
					users={users}
					admins={admins}
				/>
			</Box>
		</Stack>
	)
}