import React from "react";
import ReactDOM from "react-dom/client";
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import AuthContextProvider, {useAuthContext} from "./contexts/auth";
import {CssBaseline, ThemeProvider} from "@mui/material";
import lightTheme from "./styles/themes/light.theme";
import * as Sentry from "@sentry/react";
import axios from "axios";

import Index from "./routes";
import Login from "./routes/login";
import Callback from "./routes/callback";
import LogOut from "./routes/logout";
import AdminIndex from "./routes/admin/index";
import AdminHistory from "./routes/admin/history";

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN
});

// Include cookies in all requests
axios.defaults.withCredentials = true;

// Set the api server as the base path, so it doesn't have to be included in every url
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

/**
 * Only allow clients who are not signed in through, otherwise redirect to main page
 */
function SignedOutRoute({children}: { children: React.ReactElement }): React.ReactElement {
	const {user} = useAuthContext();

	if (user) return <Navigate to="/"/>;

	return children;
}

/**
 * Only allow non admin signed-in users through
 */
function UserAuthenticatedRoute({children}: { children: React.ReactElement }): React.ReactElement {
	const {user} = useAuthContext();

	if (!user) return <Navigate to="/login"/>;
	if (user.admin) return <Navigate to="/admin"/>;

	return children;
}

/**
 * Only allow admins through
 */
function AdminAuthenticatedRoute({children}: { children: React.ReactElement }): React.ReactElement {
	const {user} = useAuthContext();

	if (!user) return <Navigate to="/login"/>;
	if (!user.admin) return <Navigate to="/"/>;

	return children;
}

const router = createBrowserRouter([
	{
		path: "/",
		element: <UserAuthenticatedRoute><Index/></UserAuthenticatedRoute>
	},
	{
		path: "login",
		element: <SignedOutRoute><Login/></SignedOutRoute>
	},
	{
		path: "callback",
		element: <SignedOutRoute><Callback/></SignedOutRoute>
	},
	{
		path: "logout",
		element: <LogOut/>
	},
	{
		path: "admin",
		element: <AdminAuthenticatedRoute><AdminIndex/></AdminAuthenticatedRoute>
	},
	{
		path: "admin/history",
		element: <AdminAuthenticatedRoute><AdminHistory/></AdminAuthenticatedRoute>
	}
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<ThemeProvider theme={lightTheme}>
			<CssBaseline/>
			<AuthContextProvider>
				<RouterProvider router={router}/>
			</AuthContextProvider>
		</ThemeProvider>
	</React.StrictMode>
);