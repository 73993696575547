import {useState} from "react";
import {Autocomplete, Box, Grid, Stack, TextField, Typography, useTheme} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {captureException} from "@sentry/react";
import generateNote from "../../lib/notePrefix";
import {IUser, signOutUser} from "../../api/admin";

/**
 * This page allows for a user to be manually signed out
 *
 * @param props.users All users in the database
 * @param props.refresh A function to refresh all the parent data, including props.users
 */
export default function ManualSignOut(props: { users: IUser[], refresh: () => void }) {
	const theme = useTheme();

	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const [autocompleteValue, setAutocompleteValue] = useState<string | null>(null);
	const [noteValue, setNoteValue] = useState<string>("");

	const handleSignOut = async (type: "DAY" | "LUNCH" | "FREE_PERIOD") => {
		setLoading(true);
		setError(null);

		const user = props.users.find(user => user.name === autocompleteValue);

		if (!user) return;

		try {
			await signOutUser(user.id, generateNote(noteValue, type));

			setAutocompleteValue(null);
			setNoteValue("");
			props.refresh();
		} catch (err: any) {
			if (err.response?.data?.error === "ERR_USER_SIGNED_OUT") {
				setError("This user has already been signed out.");
				setAutocompleteValue(null);
				setNoteValue("");
				return;
			}

			console.error(err);
			captureException(err);
			setError("There was an unknown error. Please try again later. This has been reported.");
		} finally {
			setLoading(false);
		}
	}

	// Create an array with only the names of the users
	const userNames = props.users.map(user => {
		return user.name;
	});

	return (
		<Stack spacing={2}>
			{error && <Typography variant="body2" sx={{color: theme.palette.error.main}}>{error}</Typography>}
			<Box>
				<Typography variant="subtitle2">Full name to sign out</Typography>
				<Autocomplete
					disablePortal
					options={userNames}
					renderInput={(params) => <TextField {...params} size="small" variant="outlined"/>}
					value={autocompleteValue}
					onChange={(event: any, newValue: string | null) => {
						setAutocompleteValue(newValue);
					}}
				/>
			</Box>
			<Box>
				<Typography variant="subtitle2">Optional Note</Typography>
				<TextField
					fullWidth
					size="small"
					variant="outlined"
					value={noteValue}
					onChange={e => setNoteValue(e.target.value)}
				/>
			</Box>
			<Box>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4}>
						<LoadingButton
							fullWidth
							variant="contained"
							size="medium"
							color="success"
							loading={loading}
							onClick={() => handleSignOut("DAY")}
							disabled={!autocompleteValue}
						>Sign Out For Day</LoadingButton>
					</Grid>
					<Grid item xs={12} sm={4}>
						<LoadingButton
							fullWidth
							variant="contained"
							size="medium"
							color="info"
							loading={loading}
							onClick={() => handleSignOut("LUNCH")}
							disabled={!autocompleteValue}
						>Sign Out For Lunch</LoadingButton>
					</Grid>
					<Grid item xs={12} sm={4}>
						<LoadingButton
							fullWidth
							variant="contained"
							size="medium"
							color="warning"
							loading={loading}
							onClick={() => handleSignOut("FREE_PERIOD")}
							disabled={!autocompleteValue}
						>Sign Out For Free Period</LoadingButton>
					</Grid>
				</Grid>
			</Box>
		</Stack>
	)
}