import {useEffect, useState} from "react";
import {captureException} from "@sentry/react";
import {Typography, useTheme} from "@mui/material";
import FullPageLoader from "../components/FullPageLoader";
import {getRedirectURL} from "../api/auth";

export default function Login() {
	const theme = useTheme();

	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		getRedirectURL()
			.then(url => {
				window.location.href = url;
			})
			.catch(err => {
				setError("There was an unknown error. Please try again later.");
				console.error(err);
				captureException(err);
			})
	}, []);

	if (error) return <Typography variant="h4" sx={{color: theme.palette.error.main}}>{error}</Typography>;

	return <FullPageLoader/>;
}