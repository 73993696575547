import LoaderCss from "../styles/Loader.module.scss";
import React from "react";

export default function Loader(props: {large?: boolean}) {
	return (
		<span className={LoaderCss.loader} style={{
			width: props.large ? "100px" : undefined,
			height: props.large ? "100px" : undefined
		}}/>
	)
}