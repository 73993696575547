import {useEffect} from "react";
import {useAuthContext} from "../contexts/auth";
import {Link} from "react-router-dom";
import {Typography} from "@mui/material";

export default function LogOut() {
	const {logout} = useAuthContext();

	useEffect(() => {
		logout();
	}, [logout]);

	return <Typography variant="h4">You have successfully been logged out. <Link to="/login">Click here to log back in</Link></Typography>
}