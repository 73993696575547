import {
	Paper, Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import {captureException} from "@sentry/react";
import generateNote from "../../lib/notePrefix";
import {completeRequest, IRequest, IUser, signOutUser} from "../../api/admin";
import gradYearFromEmail from "../../lib/gradYearFromEmail";

/**
 * Generate a row in the table for a request
 *
 * @param props.request The object of the request to generate the row for
 * @param props.user The user that created the request
 * @param props.setError A callback function if an error occurs inside of this function
 * @param props.refresh A function to refresh the data on the admin page
 */
const RequestRow = (props: { request: IRequest, user: IUser, setError: (error: string | null) => void, refresh: () => void }) => {
	const theme = useTheme();

	const [loading, setLoading] = useState<boolean>(false);

	// Create two different prototypes of functions. The top is for deny, and the bottom is for approve
	async function respondToRequest(request: IRequest, method: "DENY"): Promise<void>;
	async function respondToRequest(request: IRequest, method: "APPROVE", type: "DAY" | "LUNCH" | "FREE_PERIOD"): Promise<void>;

	/* Create the third prototype, which accepts a deny OR request. The reason type is optional (?) is that on the
	"DENY" function, type is not passed, but on the "APPROVE" function, type is passed. */
	async function respondToRequest(request: IRequest, method: "APPROVE" | "DENY", type?: "DAY" | "LUNCH" | "FREE_PERIOD"): Promise<void> {
		props.setError(null);
		setLoading(true);

		try {
			// Sign out the user if the request is approved
			if (method === "APPROVE") await signOutUser(request.userId, generateNote(null, type!));

			// Complete the request if the user is signed in or not
			await completeRequest(request.id);

			// Refresh the entire admin page data
			props.refresh();
		} catch (err: any) {
			if (err.response?.data?.error === "ERR_USER_SIGNED_OUT") {
				props.setError("This user has already been signed out.");
				return;
			}

			console.error(err);
			captureException(err);
			props.setError("There was an unknown error. Please try again later.");
		} finally {
			setLoading(false);
		}
	}

	const gradYear = gradYearFromEmail(props.user.email);

	return (
		<TableRow style={{borderBottom: "2px dotted " + theme.palette.grey[600]}}>
			<TableCell align="center">{props.user.name} {gradYear}</TableCell>
			<TableCell align="center">{new Date(props.request.timestamp).toLocaleString()}</TableCell>
			<TableCell align="center">{props.request.note}</TableCell>
			<TableCell align="center">
				<Stack spacing={0.5}>
					<LoadingButton
						variant="contained"
						color="error"
						loading={loading}
						onClick={() => respondToRequest(props.request, "DENY")}
					>Deny</LoadingButton>
					<LoadingButton
						variant="contained"
						color="success"
						loading={loading}
						onClick={() => respondToRequest(props.request, "APPROVE", "DAY")}
					>Approve for day</LoadingButton>
					<LoadingButton
						variant="contained"
						color="info"
						loading={loading}
						onClick={() => respondToRequest(props.request, "APPROVE", "LUNCH")}
					>Approve for lunch</LoadingButton>
					<LoadingButton
						variant="contained"
						color="warning"
						loading={loading}
						onClick={() => respondToRequest(props.request, "APPROVE", "FREE_PERIOD")}
					>Approve for free period</LoadingButton>
				</Stack>
			</TableCell>
		</TableRow>
	)
}

/**
 * A table of all sign out requests.
 *
 * @param props.requests An array of all requests to render
 * @param props.users An array of all users in the database
 * @param props.refresh A function to refresh the parent data, which includes requests and users.
 */
export default function SignOutRequests(props: { requests: IRequest[], users: IUser[], refresh: () => void }) {
	const theme = useTheme();
	const [error, setError] = useState<string | null>(null);

	return (
		<>{error && <Typography variant="body2" sx={{color: theme.palette.error.main}}>{error}</Typography>}
			<TableContainer component={Paper}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="center" sx={{fontWeight: "bold"}}>Name</TableCell>
							<TableCell align="center" sx={{fontWeight: "bold"}}>Time</TableCell>
							<TableCell align="center" sx={{fontWeight: "bold"}}>Note</TableCell>
							<TableCell align="center" sx={{fontWeight: "bold"}}>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.requests.length === 0 &&
							<TableRow>
								<TableCell colSpan={4} align="center">
									<Typography variant="body1">No users have requested a sign out yet.</Typography>
								</TableCell>
							</TableRow>
						}

						{props.requests.map(request => {
							// Get the user that the request belongs to
							const user = props.users.find(user => user.id === request.userId);

							// If the user can't be found, don't render the row
							if (!user) return <></>;

							return (
								<RequestRow
									key={request.id}
									request={request}
									user={user}
									setError={(error) => setError(error)}
									refresh={props.refresh}
								/>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}