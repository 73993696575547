import {Typography} from "@mui/material";
import {useEffect, useState} from "react";

export default function CurrentTime() {
	const [time, setTime] = useState<Date>(new Date());

	useEffect(() => {
		let interval: any = null;

		interval = setInterval(() => {
			setTime(new Date());
		}, 1000);

		return () => clearInterval(interval);
	}, [time])

	return (
		<Typography variant="h4" textAlign="center">Current Time {time.toLocaleString()}</Typography>
	)
}