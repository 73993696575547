import React from "react";
import {Stack} from "@mui/material";
import Loader from "./Loader";

export default function FullPageLoader(props: {large?: boolean}) {
	return (
		<Stack alignItems="center" justifyContent="center" height="80vh" width="100%">
			<Loader large={props.large}/>
		</Stack>
	)
}