import {Stack} from "@mui/material";
import LogoutButton from "./LogoutButton";

import logo from "../assets/logo.png";

export default function MainNavigationBar() {
	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center" position="relative" width="100%" pt={2}>
			<img src={logo} alt="Logo" style={{
				left: "5px",
				height: "50px",
				width: "auto"
			}}/>
			<LogoutButton/>
		</Stack>
	)
}