import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {ISignOut, IUser} from "../../api/admin";
import gradYearFromEmail from "../../lib/gradYearFromEmail";

/**
 * Render a table of all users that have been signed back in today
 *
 * @param props.history A list of all users that have been signed back in
 * @param props.users A list of all users in the database
 * @param props.admins A list of all admins in the database
 */
export default function SignOutHistory(props: { history: ISignOut[], users: IUser[], admins: IUser[] }) {
	// Sort sign outs by the most recent timestamp in first
	const sortedHistory = props.history.sort((a, b) => {
		return new Date(b.timestampIn).getTime() - new Date(a.timestampIn).getTime();
	});

	return (
		<TableContainer component={Paper}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell align="center" sx={{fontWeight: "bold"}}>Name</TableCell>
						<TableCell align="center" sx={{fontWeight: "bold"}}>Time Out</TableCell>
						<TableCell align="center" sx={{fontWeight: "bold"}}>Time In</TableCell>
						<TableCell align="center" sx={{fontWeight: "bold"}}>Admin</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{sortedHistory.length === 0 &&
						<TableRow>
							<TableCell colSpan={4} align="center">
								<Typography variant="body1">No users have been signed back in.</Typography>
							</TableCell>
						</TableRow>
					}

					{sortedHistory.map(history => {
						const user = props.users.find(user => user.id === history.userId);

						if (!user) return <></>;

						const gradYear = gradYearFromEmail(user.email);

						// Find the admin that signed out the user
						const admin = props.admins.find(admin => admin.id === history.adminId);

						return (
							<TableRow key={history.id}>
								<TableCell align="center">{user.name} {gradYear}</TableCell>
								<TableCell align="center">{new Date(history.timestampOut).toLocaleString()}</TableCell>
								<TableCell align="center">{new Date(history.timestampIn).toLocaleString()}</TableCell>
								<TableCell align="center">{admin?.name || "N/A"}</TableCell>
							</TableRow>
						)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}