/**
 * Generate a note with a sign out type
 * @param note Optional note
 * @param type Type of sign out
 */
export default function generateNote(note: string | null, type: "DAY" | "LUNCH" | "FREE_PERIOD") {
	let notePrefix = "";

	if (type === "DAY") {
		notePrefix = "Day";
	} else if (type === "LUNCH") {
		notePrefix = "Lunch";
	} else if (type === "FREE_PERIOD") {
		notePrefix = "Free Period";
	}

	if (note) notePrefix += ` | ${note}`;

	return notePrefix;
}