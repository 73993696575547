import axios from "axios";

export interface ICurrentUser {
	admin: boolean;
	name: string;
}

/**
 * Get the currently signed-in user from the database
 */
export async function getCurrentUser(): Promise<ICurrentUser> {
	try {
		const response = await axios.get("/user");

		return response.data;
	} catch (err: any) {
		if (err.request || (err.response && err.response.status >= 500)) throw new Error("NETWORK_ERROR");

		throw err;
	}
}

export interface IUserStatus {
	status: boolean;
	request: boolean;
	lunch: boolean;
	timestampOut: Date;
}

/**
 * Get the current user's signout status
 */
export async function getUserStatus(): Promise<IUserStatus> {
	const response = await axios.get("/user/status");

	return response.data;
}