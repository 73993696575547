import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import {captureException} from "@sentry/react";
import {ISignOut, IUser, signInUser} from "../../api/admin";
import gradYearFromEmail from "../../lib/gradYearFromEmail";

/**
 * Render a row for a sign out in a table
 *
 * @param props.signout Object of the sign-out
 * @param props.user Object of the user that the sign-out belongs to
 * @param props.admin Object of the admin that signed out the user
 * @param props.setError Function that gets called if an error occurs when signing back in a user
 * @param props.refresh Function that gets called to refresh the data
 */
function UserRow(props: { signout: ISignOut, user: IUser, admin: IUser | null, setError: (error: string | null) => void, refresh?: () => void }) {
	const [loading, setLoading] = useState<boolean>(false);

	const signBackInUser = async () => {
		props.setError(null);
		setLoading(true);

		try {
			await signInUser(props.user.id);

			props.refresh!();
		} catch (err: any) {
			if (err.response.data.error === "ERR_USER_NOT_SIGNED_OUT") {
				props.setError("Another admin has signed in this user.");
				return;
			}

			console.error(err);
			captureException(err);
			props.setError("There was an unknown error. Please try again later.");
		} finally {
			setLoading(false);
		}
	}

	const gradYear = gradYearFromEmail(props.user.email);

	return (
		<TableRow key={props.user.id}>
			<TableCell align="center">{props.user.name} {gradYear}</TableCell>
			<TableCell
				align="center">{new Date(props.signout.timestampOut).toLocaleString()}{props.signout.note && ` | ${props.signout.note}`}</TableCell>
			<TableCell align="center">{props.admin?.name || "N/A"}</TableCell>
			{props.refresh &&
				<TableCell align="center">
					<LoadingButton
						variant="contained"
						color="success"
						loading={loading}
						onClick={signBackInUser}
					>Sign In</LoadingButton>
				</TableCell>
			}
		</TableRow>
	)
}

/**
 * Render a list of all sign out students. If the refresh param is included, then the sign in button will also be
 * rendered.
 *
 * @param props.signouts A list of all sign outs
 * @param props.users A list of all users in the database
 * @param props.admins A list of all admins in the database
 * @param props.refresh A function to refresh all the parent data, including users and signouts. This param is
 *     optional. If this param is not included, then the sign-in button for each sign out will not be rendered
 */
export default function SignedOutStudents(props: { signouts: ISignOut[], users: IUser[], admins: IUser[], refresh?: () => void }) {
	const theme = useTheme();

	const [error, setError] = useState<string | null>(null);

	// Sort sign outs by the most recent first
	const sortedSignouts = props.signouts.sort((a, b) => {
		return new Date(b.timestampOut).getTime() - new Date(a.timestampOut).getTime();
	});

	return (
		<>
			{error && <Typography variant="body2" sx={{color: theme.palette.error.main}}>{error}</Typography>}
			<TableContainer component={Paper}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="center" sx={{fontWeight: "bold"}}>Name</TableCell>
							<TableCell align="center" sx={{fontWeight: "bold"}}>Time Out | Note</TableCell>
							<TableCell align="center" sx={{fontWeight: "bold"}}>Admin</TableCell>
							{props.refresh && <TableCell align="center" sx={{fontWeight: "bold"}}>Actions</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{sortedSignouts.length === 0 &&
							<TableRow>
								<TableCell colSpan={4} align="center">
									<Typography variant="body1">No users are currently signed out.</Typography>
								</TableCell>
							</TableRow>
						}
						{sortedSignouts.map(signout => {
							// Find the user that the signout belongs to
							const user = props.users.find(user => user.id === signout.userId);

							// If the user does not exist, don't render
							if (!user) return <></>;

							// Find the admin that signed out the user
							const admin = props.admins.find(admin => admin.id === signout.adminId) || null;

							return (
								<UserRow
									key={user.id}
									signout={signout}
									user={user}
									admin={admin}
									setError={(error) => setError(error)}
									refresh={props.refresh}
								/>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}