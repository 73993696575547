import {Grid, Link, Typography, Box, Stack, useTheme, Button} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import ManualSignOut from "../../components/admin/ManualSignOut";
import SignOutRequests from "../../components/admin/SignOutRequests";
import SignedOutStudents from "../../components/admin/SignedOutStudents";
import SignOutHistory from "../../components/admin/SignOutHistory";
import {useAuthContext} from "../../contexts/auth";
import {useCallback, useEffect, useState} from "react";
import {captureException} from "@sentry/react";
import FullPageLoader from "../../components/FullPageLoader";
import LaunchIcon from "@mui/icons-material/Launch";
import {getAllRequests, getAllUsers, getSignouts, IRequest, ISignOut, IUser} from "../../api/admin";

export default function AdminDashboard() {
	const theme = useTheme();
	const auth = useAuthContext();

	const [initialLoading, setInitialLoading] = useState<boolean>(true);
	const [globalError, setGlobalError] = useState<string | null>(null);

	const [users, setUsers] = useState<IUser[]>([]);
	const [admins, setAdmins] = useState<IUser[]>([]);
	const [signOutRequests, setSignOutRequests] = useState<IRequest[]>([]);
	const [signOutHistory, setSignOutHistory] = useState<ISignOut[]>([]);
	const [signOuts, setSignOuts] = useState<ISignOut[]>([]);

	const refreshData = useCallback(() => {
		const loadUsers = async () => {
			const users = await getAllUsers();

			setUsers(users.users);
			setAdmins(users.admins);
		}

		const loadRequests = async () => {
			const requests = await getAllRequests();

			setSignOutRequests(requests);
		}

		const loadSignOuts = async () => {
			const {history, signouts} = await getSignouts();

			setSignOutHistory(history);
			setSignOuts(signouts);
		}

		Promise.all([loadUsers(), loadRequests(), loadSignOuts()])
			.catch(err => {
				console.error(err);
				captureException(err);
				setGlobalError("There was an unknown error. Please try again later.");
			})
			.finally(() => {
				setInitialLoading(false);
			});
	}, []);

	// Get data on initial load of page
	useEffect(() => {
		refreshData();
	}, [refreshData]);

	useEffect(() => {
		let interval: any = null;

		interval = setInterval(() => {
			refreshData();
		}, 5000);

		return () => clearInterval(interval);
	}, [refreshData]);

	if (initialLoading) return <FullPageLoader/>;
	if (globalError) return <Typography variant="h4" sx={{color: theme.palette.error.main}}>{globalError}</Typography>;

	return (
		<Box pl={5} pr={5} pt={4}>
			<Grid container spacing={3} justifyContent="center">
				<Grid item xs={12}>
					<Typography variant="h4">XRDS Sign Out</Typography>
					<Typography variant="h4">Admin Dashboard</Typography>
					<Typography variant="body1">Signed in as <b>{auth.user!.name}</b></Typography>
					<Link component={RouterLink} variant="body1" to="/logout">Logout</Link>
					<hr/>
				</Grid>
				<Grid item md={6} xs={12}>
					<ManualSignOut users={users} refresh={refreshData}/>

					<hr/>

					<Typography variant="body1" fontWeight="bold">Requests:</Typography>
					<SignOutRequests requests={signOutRequests} users={users} refresh={refreshData}/>
				</Grid>
				<Grid item md={6} xs={12}>
					<Stack spacing={2}>
						<Box>
							<Typography variant="body1" fontWeight="bold">Currently signed out students:</Typography>
							<SignedOutStudents
								signouts={signOuts}
								users={users}
								admins={admins}
								refresh={refreshData}
							/>
						</Box>

						<hr/>

						<Box>
							<Stack direction="row" alignItems="center" justifyContent="space-between">
								<Typography variant="body1" fontWeight="bold">Signout history for today:</Typography>
								<Button variant="text" component={RouterLink} to="/admin/history">
									History <LaunchIcon fontSize="small"/>
								</Button>
							</Stack>
							<SignOutHistory
								history={signOutHistory}
								admins={admins}
								users={users}
							/>
						</Box>
					</Stack>
				</Grid>
			</Grid>
		</Box>
	);
}