import {createTheme, PaletteOptions, SimplePaletteColorOptions} from "@mui/material/styles";

const palette: PaletteOptions = {
	mode: "light",
	common: {
		black: "#111936"
	},
	primary: {
		light: "#90caf9",
		main: "#0c8bfd",
		dark: "#2047f4"
	},
	secondary: {
		light: "#ede7f6",
		main: "#b39ddb",
		dark: "#5e35b1"
	},
	error: {
		light: "#ef9a9a",
		main: "#ea5858",
		dark: "#c62828"
	},
	warning: {
		light: "#fff8e1",
		main: "#ffc810",
		dark: "#ffc107"
	},
	success: {
		light: "#b9f6ca",
		main: "#45cc7b",
		dark: "#00c853"
	},
	grey: {
		50: "#fafafa",
		100: "#f5f5f5",
		500: "#9e9e9e",
		600: "#757575",
		700: "#585d5f",
		900: "#1a1a1a"
	},
	text: {
		primary: "#1a1a1a",
		secondary: "#585d5f"
	},
	divider: "#eeeeee",
	background: {
		paper: "#ffffff",
		default: "#eef0f1"
	}
};

const theme = createTheme({
	palette,
	mixins: {
		toolbar: {
			minHeight: "48px",
			padding: "16px",
			"@media (min-width: 600px)": {
				minHeight: "48px"
			}
		}
	},
	typography: {
		fontFamily: "'Open SansVariable', sans-serif",
		h6: {
			fontWeight: 600,
		},
		h5: {
			fontWeight: 500
		},
		h4: {
			fontWeight: 700
		},
		h3: {
			fontWeight: 600
		},
		h2: {
			fontWeight: 700
		},
		h1: {
			fontWeight: 700
		},
		subtitle1: {
			fontWeight: 500,
			color: palette.text!.primary
		},
		subtitle2: {
			fontWeight: 400,
			color: palette.text!.secondary
		},
		caption: {
			color: palette.text!.secondary,
			fontWeight: 400
		},
		body1: {
			fontWeight: 400,
		},
		body2: {
			letterSpacing: "0em",
			fontWeight: 400,
			color: palette.text!.primary
		},
		button: {
			textTransform: "capitalize"
		}
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: 500,
					borderRadius: "4px",
					boxShadow: "none"
				}
			}
		},
		MuiPaper: {
			defaultProps: {
				elevation: 0
			},
			styleOverrides: {
				root: {
					backgroundImage: "none",
					borderWidth: "1px",
					borderStyle: "solid",
					borderColor: palette.grey![900]
				},
				rounded: {
					borderRadius: "4px"
				}
			}
		},
		MuiCardHeader: {
			styleOverrides: {
				root: {
					color: palette.text!.secondary,
					padding: "24px"
				},
				title: {
					fontSize: "1.125rem"
				}
			}
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: "24px"
				}
			}
		},
		MuiCardActions: {
			styleOverrides: {
				root: {
					padding: "24px"
				}
			}
		},
		MuiAlert: {
			styleOverrides: {
				root: {
					alignItems: "center"
				},
				outlined: {
					border: "1px dashed"
				}
			}
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					color: palette.text!.primary,
					paddingTop: "10px",
					paddingBottom: "10px",
					"&.Mui-selected": {
						color: ((palette.secondary as SimplePaletteColorOptions) as SimplePaletteColorOptions)!.main,
						backgroundColor: ((palette.secondary as SimplePaletteColorOptions) as SimplePaletteColorOptions)!.light,
						"&:hover": {
							backgroundColor: ((palette.secondary as SimplePaletteColorOptions) as SimplePaletteColorOptions)!.light
						},
						"& .MuiListItemIcon-root": {
							color: ((palette.secondary as SimplePaletteColorOptions) as SimplePaletteColorOptions)!.dark
						}
					},
					"&:hover": {
						backgroundColor: (palette.secondary as SimplePaletteColorOptions)!.light,
						color: (palette.secondary as SimplePaletteColorOptions)!.dark,
						"& .MuiListItemIcon-root": {
							color: (palette.secondary as SimplePaletteColorOptions)!.dark
						}
					}
				}
			}
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: palette.text!.primary,
					minWidth: "36px"
				}
			}
		},
		MuiListItemText: {
			styleOverrides: {
				primary: {
					color: palette.text!.secondary
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					color: palette.text!.secondary,
					"&::placeholder": {
						color: palette.text!.secondary,
						fontSize: "0.875rem"
					}
				}
			}
		},
		MuiLink: {
			styleOverrides: {
				root: {
					textDecoration: "none"
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					background: palette.grey![50],
					borderRadius: "8px",
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: palette.grey![400]
					},
					"&:hover $notchedOutline": {
						borderColor: (palette.primary as SimplePaletteColorOptions)!.light
					},
					"&.MuiInputBase-multiline": {
						padding: 1
					}
				},
				input: {
					fontWeight: 500,
					background: palette.grey![50],
					padding: "15.5px 14px",
					borderRadius: "8px",
					"&.MuiInputBase-inputSizeSmall": {
						padding: "10px 14px",
						"&.MuiInputBase-inputAdornedStart": {
							paddingLeft: 0
						}
					}
				},
				inputAdornedStart: {
					paddingLeft: 4
				},
				notchedOutline: {
					borderRadius: "8px"
				}
			}
		},
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					"& .MuiAutocomplete-tag": {
						background: (palette.secondary as SimplePaletteColorOptions)!.light,
						borderRadius: 4,
						color: palette.text!.secondary,
						".MuiChip-deleteIcon": {
							color: (palette.secondary as SimplePaletteColorOptions)!.light
						}
					}
				},
				popper: {
					borderRadius: "8px",
					boxShadow: "0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)"
				}
			}
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderColor: palette.divider,
					opacity: 1
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				select: {
					"&:focus": {
						backgroundColor: "transparent"
					}
				}
			}
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					color: (palette.primary as SimplePaletteColorOptions)!.dark,
					background: (palette.primary as SimplePaletteColorOptions)!.light
				}
			}
		},
	}
});

export default theme;