import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import axios from "axios";
import {Typography, useTheme} from "@mui/material";
import {captureException} from "@sentry/react";

export default function RequestSignOutBtn(props: {defaultStatus: boolean}) {
	const theme = useTheme();

	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [requestStatus, setRequestStatus] = useState<boolean>(props.defaultStatus);

	const toggleRequest = async (type: "LUNCH" | "DAY") => {
		setLoading(true);

		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/request`, {
				type
			})

			setRequestStatus(response.data.status);
		} catch (err) {
			console.error(err);
			captureException(err);
			setError("There was an unknown error. Please try again. This has been reported.");
		} finally {
			setLoading(false);
		}
	}

	return (
		<>
			{error && <Typography variant="body2" sx={{color: theme.palette.error.main}}>{error}</Typography>}
			<LoadingButton
				variant="contained"
				size="large"
				fullWidth
				loading={loading}
				onClick={() => toggleRequest("LUNCH")}
			>{(requestStatus) ? "Cancel request" : "Request Lunch Sign Out"}</LoadingButton>
			<LoadingButton
				variant="contained"
				size="large"
				fullWidth
				loading={loading}
				onClick={() => toggleRequest("DAY")}
			>{(requestStatus) ? "Cancel request" : "Request Day Sign Out"}</LoadingButton>
		</>
	)
}