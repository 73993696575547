import React, {useState, createContext, useEffect, useContext, useMemo} from "react";
import FullPageLoader from "../components/FullPageLoader";
import {Stack, Typography, useTheme} from "@mui/material";
import * as userApi from "../api/user";
import {ICurrentUser} from "../api/user";
import * as authApi from "../api/auth";

interface IAuthContext {
	user?: ICurrentUser;
	refreshUser: () => Promise<void>;
	logout: () => void;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const useAuthContext = () => useContext(AuthContext);

/**
 * This function is a top level function of the entire app. It stores the currently signed-in user in the `user` state
 * and allows any child function to access the user data without having to make its own separate call to the server.
 * To use this function in any child component, simply call the useAuthContext() function above, and you will have access
 * to any variable in the IAuthContext interface
 */
export default function AuthContextProvider({children}: { children: React.ReactNode }) {
	const theme = useTheme();

	const [user, setUser] = useState<ICurrentUser>();
	const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);

	// Check if a current session exists
	useEffect(() => {
		setLoadingInitial(true);

		userApi.getCurrentUser()
			.then(user => setUser(user))
			.catch(error => {
				if (error === "NETWORK_ERROR") {
					setError(true)
				}
			})
			.finally(() => {
				setLoadingInitial(false)
			})
	}, []);

	async function refreshUser() {
		const user = await userApi.getCurrentUser()

		setUser(user);
	}

	function logout() {
		authApi.logout()
			.then(_ => setUser(undefined))
	}

	const LoaderContainer = () => (
		<Stack sx={{width: "100vw", height: "100vh"}} justifyContent="center" alignItems="center">
			<FullPageLoader/>
		</Stack>
	);

	// Make the provider update only when it should.
	// We only want to force re-renders if the user changes
	const memoedValue = useMemo(
		() => ({
			user,
			refreshUser,
			logout
		}),
		[user]
	)

	return (
		<AuthContext.Provider value={memoedValue}>
			{loadingInitial && <LoaderContainer/>}
			{!loadingInitial && error &&
				<Typography variant="h5" color={theme.palette.error.main}>There was an unknown error. Please try again
					later.</Typography>}
			{!loadingInitial && !error && children}
		</AuthContext.Provider>
	);
}