import {useCallback, useEffect, useState} from "react";
import {Container, Stack, Typography, useTheme} from "@mui/material";
import MainNavigationBar from "../components/MainNavigationBar";
import {useAuthContext} from "../contexts/auth";
import FullPageLoader from "../components/FullPageLoader";
import checkImg from "../assets/check.svg";
import xImg from "../assets/x.svg";
import {captureException} from "@sentry/react";
import CurrentTime from "../components/CurrentTime";
import RequestSignOutBtn from "../components/RequestSignOutBtn";
import {getUserStatus, IUserStatus} from "../api/user";

export default function Index() {
	const auth = useAuthContext();
	const theme = useTheme();

	const [error, setError] = useState<string | null>(null);
	const [userStatus, setUserStatus] = useState<IUserStatus>();

	// Function to get updated user status
	const fetchData = useCallback(() => {
		getUserStatus()
			.then(status => {
				setUserStatus(status);
			})
			.catch(err => {
				console.error(err);
				captureException(err);
				setError("There was an unknown error. Please try again later.");
			})
	}, []);

	// Get data on initial load of page
	useEffect(() => {
		fetchData();
	}, [fetchData]);

	// Create an interval to update the user state every 5 seconds
	useEffect(() => {
		let timeout: any = null;

		timeout = setInterval(() => {
			fetchData();
		}, 5000);

		return () => clearInterval(timeout);
	}, [fetchData]);

	return (
		<Container maxWidth="sm">
			<MainNavigationBar/>
			{error &&
				<Typography variant="h5" sx={{color: theme.palette.error.main}} textAlign="center">{error}</Typography>}
			{!error && !userStatus && <FullPageLoader/>}
			{!error && userStatus &&
				<Stack
					alignItems="center"
					justifyContent="center"
					spacing={1}
					pt={2}
					sx={{
						minHeight: "80vh",
						width: "100%"
					}}
				>
					<Typography variant="h3" textAlign="center">{auth.user!.name}</Typography>

					<img src={userStatus.status ? checkImg : xImg} alt="Check Mark" style={{
						width: "75%",
						maxHeight: "40vh"
					}}/>

					{userStatus.status ? <>
						{/* User is signed out */}

						{userStatus.lunch &&
							<Typography variant="h6" textAlign="center">Please remember to sign back in</Typography>}

						<CurrentTime/>

						<Typography variant="h5" textAlign="center">Signed out
							at {new Date(userStatus.timestampOut!).toLocaleString()}</Typography>
					</> : <>
						{/* User is not signed out */}
						<RequestSignOutBtn defaultStatus={userStatus.request}/>
					</>}
				</Stack>}
		</Container>
	)
}